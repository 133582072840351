<template>
  <v-container fluid>
    <v-card class="mt-5">
      <v-toolbar flat color="bg_section">
        <v-toolbar-title>Crea Nuovo Piano</v-toolbar-title>
      </v-toolbar>
      <v-divider />
      <v-card-text>
        <PlansForm :title="null" @submitted="onSubmitted" />
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
import PlansForm from '@components/plans/PlansForm.vue'

export default {
  name: 'PlansCreate',
  page: {
    title: 'Crea piano',
  },
  components: { PlansForm },
  methods: {
    ...mapMutations('plans', {
      reset: 'RESET_CURRENT',
    }),
    onSubmitted() {
      this.reset()
      this.$router.push({ name: 'plans_search' })
    },
  },
}
</script>
